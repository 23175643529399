










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import ItemBrandList from "@/components/organisms/item_brand/ItemBrandList.vue";

export default {
  components: {
    SubPageTemplate,
    ItemBrandList,
  },
};
