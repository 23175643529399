
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, alphaNum, integer } from "vuelidate/lib/validators";

@Component
export default class MixinItemBrandEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //名称
      name: { required },
      //名称（カナ）
      nameKana: { required },
      //スラッグ名称
      slugName: { required, alphaNum },
      //表示順
      displayOrder: { required, integer },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 名称の入力エラーメッセージを返します.
   */
  get nameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.name?.$dirty) return errors;
    !this.$v.item.name?.required && errors.push("名称を入力してください.");
    return errors;
  }
  /**
   * 名称（カナ）の入力エラーメッセージを返します.
   */
  get nameKanaErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.nameKana?.$dirty) return errors;
    !this.$v.item.nameKana?.required &&
      errors.push("名称（カナ）を入力してください.");
    return errors;
  }
  /**
   * スラッグ名称の入力エラーメッセージを返します.
   */
  get slugNameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.slugName?.$dirty) return errors;
    !this.$v.item.slugName?.required &&
      errors.push("スラッグ名称を入力してください.");
    !this.$v.item.slugName?.alphaNum &&
      errors.push("スラッグ名称は半角英数字のみです.");
    return errors;
  }
  /**
   * 表示順の入力エラーメッセージを返します.
   */
  get displayOrderErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.displayOrder?.$dirty) return errors;
    !this.$v.item.displayOrder?.required &&
      errors.push("表示順を入力してください.");
    !this.$v.item.displayOrder?.integer && errors.push("表示順は数値のみです.");
    return errors;
  }
}
