













































import { Component, Mixins, Ref } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import itemBrandService from "@/service/itemBrandService";
import ItemBrandDeleteDialog from "@/components/organisms/item_brand/ItemBrandDeleteDialog.vue";
import ItemBrandEditDialog from "@/components/organisms/item_brand/ItemBrandEditDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import { ItemBrand } from "@/graphql/client";

@Component({ components: { ItemBrandDeleteDialog, ItemBrandEditDialog } })
export default class ItemBrandList extends Mixins(MixinFormatter) {
  @Ref() readonly editDialog!: ItemBrandEditDialog;
  @Ref() readonly deleteDialog!: ItemBrandDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: ItemBrand[] = [];
  headers = [
    {
      text: "スラッグ名称",
      value: "slugName",
      width: "15%",
    },
    {
      text: "名称",
      value: "name",
    },
    {
      text: "名称（カナ）",
      value: "nameKana",
    },
    {
      text: "表示順",
      value: "displayOrder",
      width: "15%",
    },
    {
      text: "有効/無効",
      value: "isAvailable",
      width: "15%",
      formatter: this.getIsAvailableLabel,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];
  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    itemBrandService.allItemBrands().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }
  /**
   * 商品ブランド編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(itemBrandService.defaultItemBrand);
  }

  /**
   * 商品ブランド編集ダイアログを表示します.
   */
  public openEditDialog(item: ItemBrand): void {
    this.editDialog.open(item);
  }

  /**
   * 商品ブランド編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: ItemBrand): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("商品ブランドを更新しました！");
  }

  /**
   * 商品ブランド編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: ItemBrand): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("商品ブランドを登録しました！");
  }

  /**
   * 商品ブランド削除ダイアログを表示します.
   */
  public openDeleteDialog(item: ItemBrand): void {
    this.deleteDialog.open(Object.assign({}, item));
  }

  /**
   * 商品ブランド削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: ItemBrand): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("商品ブランドを削除しました！");
  }
}
