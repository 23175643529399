
































































































































import { ItemBrand } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import itemBrandService from "@/service/itemBrandService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import ImagePicker from "@/components/molecules/ImagePicker.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/item_brand/MixinItemBrandEditDialogValidator.vue";

@Component({
  components: {
    ProgressLinear,
    ImagePicker,
  },
})
export default class ItemBrandEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly imagePicker!: ImagePicker;
  @Ref() readonly itemForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: ItemBrand = itemBrandService.defaultItemBrand;
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  get formattedCreatedAt(): string {
    return this.formatDateTime(this.item.createdAt);
  }
  get formattedUpdatedAt(): string {
    return this.formatDateTime(this.item.updatedAt);
  }
  get formattedIsAvailable(): string {
    return this.getIsAvailableLabel(
      this.item.isAvailable ? this.item.isAvailable : false
    );
  }
  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: ItemBrand): Promise<void> {
    this.item = itemBrandService.defaultItemBrand;
    if (item.guid) {
      const brand = await itemBrandService.getItemBrand(item.guid);
      if (brand) {
        this.item = brand;
      }
    }
  }
  /**
   * ダイアログを表示します.
   */
  public async open(item: ItemBrand): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.imagePicker.reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        itemBrandService
          .updateItemBrand(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyUpdateSuccess(result as ItemBrand);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("商品ブランドを更新できませんでした.");
          });
      } else {
        //create
        itemBrandService
          .createItemBrand(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyCreateSuccess(result as ItemBrand);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("商品ブランドを登録できませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: ItemBrand): ItemBrand {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: ItemBrand): ItemBrand {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(massage: string): string {
    return massage;
  }
}
