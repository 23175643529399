









































import { ItemBrand } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import itemBrandService from "@/service/itemBrandService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class ItemBrandEditDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: ItemBrand = itemBrandService.defaultItemBrand;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: ItemBrand): void {
    this.item = item;

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  deleteItem(): void {
    this.isProgressing = true;
    //delete
    itemBrandService.deleteItemBrand(this.item).then((result) => {
      this.isProgressing = false;
      this.notifySuccess(result as ItemBrand);
    });
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(deleted: ItemBrand): ItemBrand {
    return deleted;
  }
}
